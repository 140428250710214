// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddSoftwareItemsToSavedCartMutationVariables = Types.Exact<{
  items: Array<Types.SavedCartItemInput> | Types.SavedCartItemInput;
}>;


export type AddSoftwareItemsToSavedCartMutation = { __typename?: 'Mutation', addSoftwareItemsToSavedCart: { __typename?: 'NewSavedCart', savedCart: { __typename?: 'SaveCartId', cartId: string } } };


export const AddSoftwareItemsToSavedCartDocument = gql`
    mutation AddSoftwareItemsToSavedCart($items: [SavedCartItemInput!]!) {
  addSoftwareItemsToSavedCart(input: {items: $items}) {
    savedCart {
      cartId
    }
  }
}
    `;
export type AddSoftwareItemsToSavedCartMutationFn = ApolloReactCommon.MutationFunction<AddSoftwareItemsToSavedCartMutation, AddSoftwareItemsToSavedCartMutationVariables>;
export type AddSoftwareItemsToSavedCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddSoftwareItemsToSavedCartMutation, AddSoftwareItemsToSavedCartMutationVariables>, 'mutation'>;

    export const AddSoftwareItemsToSavedCartComponent = (props: AddSoftwareItemsToSavedCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddSoftwareItemsToSavedCartMutation, AddSoftwareItemsToSavedCartMutationVariables> mutation={AddSoftwareItemsToSavedCartDocument} {...props} />
    );
    

/**
 * __useAddSoftwareItemsToSavedCartMutation__
 *
 * To run a mutation, you first call `useAddSoftwareItemsToSavedCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSoftwareItemsToSavedCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSoftwareItemsToSavedCartMutation, { data, loading, error }] = useAddSoftwareItemsToSavedCartMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useAddSoftwareItemsToSavedCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSoftwareItemsToSavedCartMutation, AddSoftwareItemsToSavedCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddSoftwareItemsToSavedCartMutation, AddSoftwareItemsToSavedCartMutationVariables>(AddSoftwareItemsToSavedCartDocument, options);
      }
export type AddSoftwareItemsToSavedCartMutationHookResult = ReturnType<typeof useAddSoftwareItemsToSavedCartMutation>;
export type AddSoftwareItemsToSavedCartMutationResult = ApolloReactCommon.MutationResult<AddSoftwareItemsToSavedCartMutation>;
export type AddSoftwareItemsToSavedCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSoftwareItemsToSavedCartMutation, AddSoftwareItemsToSavedCartMutationVariables>;