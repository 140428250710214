// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddToSavedCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['ID'];
  items: Array<Types.SavedCartItemInput> | Types.SavedCartItemInput;
}>;


export type AddToSavedCartMutation = { __typename?: 'Mutation', addItemsToSavedCart: boolean };


export const AddToSavedCartDocument = gql`
    mutation AddToSavedCart($cartId: ID!, $items: [SavedCartItemInput!]!) {
  addItemsToSavedCart(input: {cartId: $cartId, items: $items})
}
    `;
export type AddToSavedCartMutationFn = ApolloReactCommon.MutationFunction<AddToSavedCartMutation, AddToSavedCartMutationVariables>;
export type AddToSavedCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddToSavedCartMutation, AddToSavedCartMutationVariables>, 'mutation'>;

    export const AddToSavedCartComponent = (props: AddToSavedCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddToSavedCartMutation, AddToSavedCartMutationVariables> mutation={AddToSavedCartDocument} {...props} />
    );
    

/**
 * __useAddToSavedCartMutation__
 *
 * To run a mutation, you first call `useAddToSavedCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToSavedCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToSavedCartMutation, { data, loading, error }] = useAddToSavedCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useAddToSavedCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddToSavedCartMutation, AddToSavedCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddToSavedCartMutation, AddToSavedCartMutationVariables>(AddToSavedCartDocument, options);
      }
export type AddToSavedCartMutationHookResult = ReturnType<typeof useAddToSavedCartMutation>;
export type AddToSavedCartMutationResult = ApolloReactCommon.MutationResult<AddToSavedCartMutation>;
export type AddToSavedCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddToSavedCartMutation, AddToSavedCartMutationVariables>;