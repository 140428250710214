// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SubmitCartMutationVariables = Types.Exact<{
  isQuote: Types.Scalars['Boolean'];
  action: Types.CartRequestAction;
  cvv?: Types.InputMaybe<Types.Scalars['String']>;
  isDuplicateCheck?: Types.InputMaybe<Types.Scalars['Boolean']>;
  totalProduct?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SubmitCartMutation = { __typename?: 'Mutation', submitCart: { __typename?: 'CartSubmission', orderId?: string | null, orderNumber?: string | null, orderStatus?: string | null, paymentRedirectURL?: string | null, errors: Array<{ __typename?: 'SAPError', errorNumber?: string | null, message?: string | null }> } };


export const SubmitCartDocument = gql`
    mutation SubmitCart($isQuote: Boolean!, $action: CartRequestAction!, $cvv: String, $isDuplicateCheck: Boolean, $totalProduct: Int) {
  submitCart(isQuote: $isQuote, action: $action, cvv: $cvv, isDuplicateCheck: $isDuplicateCheck, totalProduct: $totalProduct) {
    orderId
    orderNumber
    errors {
      errorNumber
      message
    }
    orderStatus
    paymentRedirectURL
  }
}
    `;
export type SubmitCartMutationFn = ApolloReactCommon.MutationFunction<SubmitCartMutation, SubmitCartMutationVariables>;
export type SubmitCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitCartMutation, SubmitCartMutationVariables>, 'mutation'>;

    export const SubmitCartComponent = (props: SubmitCartComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitCartMutation, SubmitCartMutationVariables> mutation={SubmitCartDocument} {...props} />
    );
    

/**
 * __useSubmitCartMutation__
 *
 * To run a mutation, you first call `useSubmitCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCartMutation, { data, loading, error }] = useSubmitCartMutation({
 *   variables: {
 *      isQuote: // value for 'isQuote'
 *      action: // value for 'action'
 *      cvv: // value for 'cvv'
 *      isDuplicateCheck: // value for 'isDuplicateCheck'
 *      totalProduct: // value for 'totalProduct'
 *   },
 * });
 */
export function useSubmitCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitCartMutation, SubmitCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubmitCartMutation, SubmitCartMutationVariables>(SubmitCartDocument, options);
      }
export type SubmitCartMutationHookResult = ReturnType<typeof useSubmitCartMutation>;
export type SubmitCartMutationResult = ApolloReactCommon.MutationResult<SubmitCartMutation>;
export type SubmitCartMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitCartMutation, SubmitCartMutationVariables>;