// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MergeSavedCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['ID'];
}>;


export type MergeSavedCartMutation = { __typename?: 'Mutation', mergeSavedCart: boolean };


export const MergeSavedCartDocument = gql`
    mutation MergeSavedCart($cartId: ID!) {
  mergeSavedCart(cartId: $cartId)
}
    `;
export type MergeSavedCartMutationFn = ApolloReactCommon.MutationFunction<MergeSavedCartMutation, MergeSavedCartMutationVariables>;
export type MergeSavedCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MergeSavedCartMutation, MergeSavedCartMutationVariables>, 'mutation'>;

    export const MergeSavedCartComponent = (props: MergeSavedCartComponentProps) => (
      <ApolloReactComponents.Mutation<MergeSavedCartMutation, MergeSavedCartMutationVariables> mutation={MergeSavedCartDocument} {...props} />
    );
    

/**
 * __useMergeSavedCartMutation__
 *
 * To run a mutation, you first call `useMergeSavedCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeSavedCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeSavedCartMutation, { data, loading, error }] = useMergeSavedCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useMergeSavedCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MergeSavedCartMutation, MergeSavedCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MergeSavedCartMutation, MergeSavedCartMutationVariables>(MergeSavedCartDocument, options);
      }
export type MergeSavedCartMutationHookResult = ReturnType<typeof useMergeSavedCartMutation>;
export type MergeSavedCartMutationResult = ApolloReactCommon.MutationResult<MergeSavedCartMutation>;
export type MergeSavedCartMutationOptions = ApolloReactCommon.BaseMutationOptions<MergeSavedCartMutation, MergeSavedCartMutationVariables>;