import gql from 'graphql-tag'

/*
 * IMPORTANT! Please do not edit this file without first making absolutely
 * sure that the fields you're adding do not hit SAP. If your fields are
 * in the cart resolver and have a full cart call it is very likely it will hit SAP.
 * Please run any changes to this file by your technical lead before merging.
 **/

export const ALL_CARTS = gql`
  query AllCarts {
    getAllCarts {
      ...QuickCart
    }
  }

  fragment QuickCart on Cart {
    id
    cartIdAlias
    count
    cartType
    items {
      ...QuickCartItem
    }
    customerServiceInstructions
    packingSlipNote
    partners {
      ...QuickCartPartners
    }
    salesOrderSource
    contractNumber
    selectedPaymentMethod
    displaySaveCartButton
    isEmproveProductExists
  }

  fragment QuickCartPartners on CartPartners {
    SalesOrg
    billTo
    contact
    payer
    shipTo
    soldTo
  }

  fragment QuickCartItem on CartItem {
    id
    quantity
    brand
    material {
      id
      description
      displaySellerName
      erpType
      brand {
        key
        name
        color
        logo {
          mediumUrl
        }
      }
      productKey
      product
      image {
        smallUrl
        mediumUrl
        largeUrl
      }
      name
      number
    }
    quoteItemNumber
    quoteNumber
    promoCode
    productNote
    yourReference
    type
    displayEditButton
    redirectLinkForEdit
    configurationId
    configuration {
      name
      value
      sapCharc
    }
    productType
    emproveDossierInfo {
      dossierId
      dossierSubType
      dossierSubLength
      dossierMaterialNumber
    }
    isSingleLotSelected
    deviceSerialNumber
    requestedDeliveryDate
  }
`
