// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CancelCartMutationVariables = Types.Exact<{
  action: Types.CartRequestAction;
}>;


export type CancelCartMutation = { __typename?: 'Mutation', cancelCart: boolean };


export const CancelCartDocument = gql`
    mutation CancelCart($action: CartRequestAction!) {
  cancelCart(action: $action)
}
    `;
export type CancelCartMutationFn = ApolloReactCommon.MutationFunction<CancelCartMutation, CancelCartMutationVariables>;
export type CancelCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelCartMutation, CancelCartMutationVariables>, 'mutation'>;

    export const CancelCartComponent = (props: CancelCartComponentProps) => (
      <ApolloReactComponents.Mutation<CancelCartMutation, CancelCartMutationVariables> mutation={CancelCartDocument} {...props} />
    );
    

/**
 * __useCancelCartMutation__
 *
 * To run a mutation, you first call `useCancelCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCartMutation, { data, loading, error }] = useCancelCartMutation({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useCancelCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCartMutation, CancelCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CancelCartMutation, CancelCartMutationVariables>(CancelCartDocument, options);
      }
export type CancelCartMutationHookResult = ReturnType<typeof useCancelCartMutation>;
export type CancelCartMutationResult = ApolloReactCommon.MutationResult<CancelCartMutation>;
export type CancelCartMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCartMutation, CancelCartMutationVariables>;