import { PureQueryOptions } from 'apollo-boost'
import { useCallback } from 'react'
import { QueryHookOptions } from 'react-apollo'
import {
  useStoreCartDataForAnalytics,
  sendAddToCartAnalyticsEvent,
} from '@src/utils/analytics/cartAndCheckoutEvents'
import {
  SnackbarMessages,
  useGlobalSnackbar,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { ORDERS_PENDING_APPROVAL } from '../queries/OrdersPendingApprovalQuery'
import { QUICK_CART } from '../queries/QuickCartQuery'
import { ALL_CARTS } from '../queries/AllCartsQuery'
import { OriginalError, extractData } from '@utils/errorHandler'
import {
  AddToCartMutationOptions,
  useAddToCartMutation,
  AddToCartMutationHookResult,
} from '@src/mutations/AddToCartMutation.generated'
import {
  CancelCartMutationOptions,
  useCancelCartMutation,
} from '@src/mutations/CancelCartMutation.generated'
import { CartItemInput, CartRequestAction } from '@src/types/graphql-types'
import {
  CartQuery,
  CartQueryVariables,
  useCartLazyQuery,
  useCartQuery,
} from '@src/queries/CartQuery.generated'
import {
  DeleteCartItemMutationOptions,
  useDeleteCartItemMutation,
  DeleteCartItemMutationFn,
  DeleteCartItemMutationHookResult,
} from '@src/mutations/DeleteCartItem.generated'
import {
  DeleteSavedCartMutationOptions,
  useDeleteSavedCartMutation,
} from '@src/mutations/DeleteSavedCart.generated'
import {
  PatchCartItemMutationOptions,
  usePatchCartItemMutation,
} from '@src/mutations/PatchCartItem.generated'
import {
  QuickCartDocument,
  QuickCartQuery,
  QuickCartQueryVariables,
  useQuickCartQuery,
} from '@src/queries/QuickCartQuery.generated'
import {
  QuickUpdateCartMutationOptions,
  useQuickUpdateCartMutation,
} from '@src/mutations/QuickUpdateCart.generated'
import {
  SaveCartMutationOptions,
  useSaveCartMutation,
} from '@src/mutations/SaveCartMutation.generated'
import {
  SubmitCartMutationOptions,
  useSubmitCartMutation,
} from '@src/mutations/SubmitCartMutation.generated'
import {
  UpdateCartItemsMutationOptions,
  UpdateQuickCartItemsMutationOptions,
  useUpdateCartItemsMutation,
  useUpdateQuickCartItemsMutation,
} from '@src/mutations/UpdateCartItems.generated'
import {
  UpdateCartMutationOptions,
  useUpdateCartMutation,
} from '@src/mutations/UpdateCart.generated'
import {
  useAllCartsQuery,
  AllCartsQuery,
  AllCartsQueryVariables,
} from '@src/queries/AllCartsQuery.generated'
import {
  useUpdateSavedCartMutation,
  UpdateSavedCartMutationOptions,
} from '@src/mutations/UpdateSavedCartMutation.generated'
import { useUpdateOrdersPendingApprovalMutation } from '@src/mutations/UpdateOrdersPendingApproval.generated'
import {
  useMergeSavedCartMutation,
  MergeSavedCartMutationOptions,
} from '@src/mutations/MergeSavedCart.generated'
import {
  useDeleteParallelCartMutation,
  DeleteParallelCartMutationOptions,
} from '@src/mutations/DeleteParallelCartMutation.generated'
import {
  useSavedCartQuery,
  SavedCartQuery,
  SavedCartQueryVariables,
} from '@src/queries/SavedCartQuery.generated'
import {
  useAddToSavedCartMutation,
  AddToSavedCartMutationOptions,
} from '@src/mutations/AddToSavedCartMutation.generated'
import {
  useCreateParallelSavedCartMutation,
  CreateParallelSavedCartMutationOptions,
} from '@src/mutations/CreateParallelSavedCartMutation.generated'
import {
  useUpdateSavedCartItemsMutation,
  UpdateSavedCartItemsMutationOptions,
} from '@src/mutations/UpdateSavedCartItemsMutation.generated'
import {
  useCreateDealerCartMutation,
  CreateDealerCartMutationOptions,
} from '@src/mutations/CreateDealerCartMutation.generated'
import { useFullCartRefetch } from '@utils/useFullCartRefetch'
import { useCurrentUser } from '@utils/useCurrentUser'
import {
  useNavigateToSavedCart,
  isValidPath,
} from '@utils/useNavigateToSavedCart'
import { CartType } from '@src/routes'
const noop = () => {
  // Added comment for avoid es-lint error
}

/**
 * Quick Cart Hook (fast)
 */
export const useQuickCart = (
  options: QueryHookOptions<QuickCartQuery, QuickCartQueryVariables>
) => {
  return useQuickCartQuery({
    ssr: false,
    ...options,
  })
}

/**
 * Cart Hook (slow)
 */
export const useFullCart = (
  options: QueryHookOptions<CartQuery, CartQueryVariables>
) => {
  const res = useCartQuery({
    ssr: false,
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    ...options,
  })
  useStoreCartDataForAnalytics(res?.data)
  return res
}

/**
 * Lazy Cart Hook (slow)
 *
 * Can be used for pre-fetching, e.g. preloading data on cart link hover
 */
export const useFullCartLazy = (
  options?: QueryHookOptions<CartQuery, CartQueryVariables>
) => {
  const res = useCartLazyQuery({
    ssr: false,
    ...options,
  })
  useStoreCartDataForAnalytics(res?.[1].data)
  return res
}

/**
 * Returns the value of refetchQueries required to refetch cart queries
 * automatically after another query returns.
 */
export const getCartRefetch = (
  id: CartRequestAction = CartRequestAction.Mini
): [PureQueryOptions] => {
  return [
    {
      query: QuickCartDocument,
      variables: { id },
    },
  ]
}

export const getAllCartRefetch = () => {
  return [
    {
      query: ALL_CARTS,
    },
  ]
}

/**
 * Add To Cart Hook (fast)
 */
export const useAddToCart = (options?: AddToCartMutationOptions) => {
  const [...rest] = useAddToCartMutation({
    ...options,
  })
  return [...rest] as AddToCartMutationHookResult
}

/**
 * Add To Cart Hook w/ Global Snackbar
 *
 * A hook that provides side-effects of common add to cart behavior
 */
export const useAddToCartWithGlobalSnackbar = () => {
  const { setSnackbar } = useGlobalSnackbar()
  const { userIsLoggedIn, currentUser } = useCurrentUser()
  const routeToSavedCartPage = useNavigateToSavedCart()
  const isBlueErpIntegrationEnabled =
    !!currentUser?.metadata?.isBlueErpIntegrationEnabled

  const sialMinicartRefetchQuery = !isBlueErpIntegrationEnabled
    ? {
        query: QUICK_CART,
        variables: { id: CartRequestAction.Mini },
      }
    : {
        query: ALL_CARTS,
      }

  const marketplaceMinicartRefetchQuery = !isBlueErpIntegrationEnabled
    ? {
        query: QUICK_CART,
        variables: { id: CartRequestAction.Marketplace },
      }
    : {
        query: ALL_CARTS,
      }

  const [addToCart] = useAddToCartMutation({
    refetchQueries: [sialMinicartRefetchQuery],
    awaitRefetchQueries: true,
  })

  const [addMarketplaceToCart] = useAddToCartMutation({
    refetchQueries: [marketplaceMinicartRefetchQuery],
    awaitRefetchQueries: true,
  })

  return useCallback(
    async (
      cartItems: CartItemInput[],
      setSubmitting: (boolean) => void = noop,
      resetForm: () => void = noop,
      marketplace?: boolean
    ) => {
      try {
        const cartType = marketplace
          ? await addMarketplaceToCart({
              variables: { input: { items: cartItems, marketplace } },
            })
          : await addToCart({
              variables: { input: { items: cartItems } },
            })

        sendAddToCartAnalyticsEvent()
        setSubmitting(false)
        resetForm()
        setSnackbar(SnackbarMessages.ItemsAddedToCart, cartItems.length)
        return cartType?.data?.addItemsToCart?.cartType as CartType
      } catch (err) {
        setSubmitting(false)
        const { hasError } = extractData(err as OriginalError)
        let errorMessage = ''
        if (
          hasError('CANNOT_CONTAIN_OTHER_PRODUCT_TYPE_WITH_EMPROVE_PRODUCTS')
        ) {
          errorMessage = SnackbarMessages.CartMixingError
        } else if (
          hasError('CANNOT_ADD_OTHER_PRODUCT_TYPE_WITH_DIGITAL_PRODUCTS')
        ) {
          errorMessage = SnackbarMessages.DigitalProductCartMixingError
        } else if (hasError('CANNOT_ADD_DIGITAL_PRODUCT_WITH_OTHERS')) {
          if (userIsLoggedIn && isValidPath()) {
            routeToSavedCartPage(cartItems, false, '')
          } else {
            errorMessage = SnackbarMessages.DigitalProductCartMixingError
          }
        } else if (
          hasError('CANNOT_CONTAIN_OTHER_PRODUCT_TYPE_WITH_DIGITAL_PRODUCTS')
        ) {
          errorMessage = SnackbarMessages.DigitalProductCartMixingError
        } else if (hasError('QUOTE_ALREADY_EXISTS_IN_CART')) {
          errorMessage = SnackbarMessages.QuoteAlreadyExistsInCart
        } else {
          errorMessage = SnackbarMessages.GenericError
        }
        errorMessage !== '' && setSnackbar(errorMessage)
        return null
      }
    },
    [setSnackbar, addToCart]
  )
}

/**
 * Delete Individual Cart Item (fast)
 *
 * Warning: Parallel delete calls may cause race conditions in ecom API; for bulk
 * delete, use updateCartItems to set quantities to 0.
 */
export const useDeleteCartItem = (options?: DeleteCartItemMutationOptions) => {
  const { isBlueErpIntegrationEnabled } = useCurrentUser()
  const { cancelFullCartRefetch } = useFullCartRefetch()
  const [deleteCartItem, result] = useDeleteCartItemMutation({
    ...options,
  })
  const cancelRefetchAndDeleteCartItem: DeleteCartItemMutationFn = (
    options
  ) => {
    cancelFullCartRefetch()
    const refetchQueries = !isBlueErpIntegrationEnabled
      ? getCartRefetch(options?.variables?.action)
      : getAllCartRefetch()
    return deleteCartItem({
      awaitRefetchQueries: true,
      refetchQueries,
      ...options,
    })
  }
  return [
    cancelRefetchAndDeleteCartItem,
    result,
  ] as DeleteCartItemMutationHookResult
}

/**
 * Update SAP cart data, e.g. shipping method (slow)
 *
 * Update cart operates as a PATCH, i.e. all update cart fields are optional
 */
export const useUpdateCart = (options?: UpdateCartMutationOptions) => {
  return useUpdateCartMutation({
    ...options,
  })
}

/**
 * Update non-SAP cart data, e.g. PO Number (fast)
 *
 * Update cart operates as a PATCH, i.e. all update cart fields are optional
 */
export const useQuickUpdateCart = (
  options?: QuickUpdateCartMutationOptions
) => {
  return useQuickUpdateCartMutation({
    ...options,
  })
}

/**
 * Update non-SAP data on a cart item, e.g. yourReference/productNote (fast)
 *
 * Patch cart item operates as a PATCH, i.e. all update fields are optional
 */
export const usePatchCartItem = (options?: PatchCartItemMutationOptions) => {
  return usePatchCartItemMutation({
    ...options,
  })
}

/**
 * Update cart item(s) promo code and/or quantity (slow)
 *
 * Update cart items operates as a PUT, i.e. not supplying a field will set it
 * to null
 *
 * May also be used to bulk delete items using quantity: 0
 */
export const useUpdateCartItems = (
  options?: UpdateCartItemsMutationOptions
) => {
  return useUpdateCartItemsMutation({
    ...options,
  })
}

/**
 * Update quick cart item(s) promo code and/or quantity (fast)
 *
 * Update quick cart items operates as a PUT, i.e. not supplying a field will set it
 * to null
 */
export const useUpdateQuickCartItems = (
  options?: UpdateQuickCartItemsMutationOptions
) => {
  return useUpdateQuickCartItemsMutation({
    ...options,
  })
}

/**
 * Submit cart as quote or order (slow)
 */
export const useSubmitCart = (options?: SubmitCartMutationOptions) => {
  const { currentUser } = useCurrentUser()
  const isBlueErpIntegrationEnabled =
    !!currentUser?.metadata?.isBlueErpIntegrationEnabled
  const refetchQueries = !isBlueErpIntegrationEnabled
    ? getCartRefetch(options?.variables?.action)
    : getAllCartRefetch()

  return useSubmitCartMutation({
    refetchQueries,
    ...options,
  })
}

/**
 * Approve cart
 */
export const useApproveCart = (id: CartRequestAction) => {
  const { currentUser } = useCurrentUser()
  const isBlueErpIntegrationEnabled =
    !!currentUser?.metadata?.isBlueErpIntegrationEnabled
  const refetchQueries = !isBlueErpIntegrationEnabled
    ? getCartRefetch(id)
    : getAllCartRefetch()
  return useUpdateOrdersPendingApprovalMutation({
    refetchQueries: [...refetchQueries, { query: ORDERS_PENDING_APPROVAL }],
    awaitRefetchQueries: true,
  })
}

/**
 * Cancel the user's cart, including all items, selected accounts/addresses,
 * and shipping/billing information saved at checkout (fast)
 */
export const useCancelCart = (options?: CancelCartMutationOptions) => {
  // Cancel cart returns the cart, but we need to refetch because the new
  // cart will have a new ID, and it's not enough to rely on caching by ID;
  // we need the queries to recognize their result is now the new ID as well.
  const { currentUser } = useCurrentUser()
  const isBlueErpIntegrationEnabled =
    !!currentUser?.metadata?.isBlueErpIntegrationEnabled
  const refetchQueries = !isBlueErpIntegrationEnabled
    ? getCartRefetch(options?.variables?.action)
    : getAllCartRefetch()
  return useCancelCartMutation({
    refetchQueries,
    ...options,
  })
}

/**
 * Save the user's cart, including all items, checkout details (i.e.
 * billing info, shipping info, PO number/requisition number, CSR notes), and
 * created date.
 */
export const useSaveCart = (options?: SaveCartMutationOptions) => {
  return useSaveCartMutation({
    ...options,
  })
}

export const useDeleteSavedCart = (
  options?: DeleteSavedCartMutationOptions
) => {
  return useDeleteSavedCartMutation({
    ...options,
  })
}

export const useMergeSavedCart = (options?: MergeSavedCartMutationOptions) => {
  return useMergeSavedCartMutation({
    ...options,
  })
}

export const useUpdateSavedCart = (
  options?: UpdateSavedCartMutationOptions
) => {
  return useUpdateSavedCartMutation({
    ...options,
  })
}

/**
 * Get saved cart using cart ID.
 * Will return saved cart details including items.
 */
export const useSavedCart = (
  options: QueryHookOptions<SavedCartQuery, SavedCartQueryVariables>
) => {
  return useSavedCartQuery({ ...options })
}

/**
 * Add items to saved cart
 */
export const useAddToSavedCart = (options?: AddToSavedCartMutationOptions) => {
  return useAddToSavedCartMutation({ ...options })
}

/**
 * Create parallel cart from saved cart
 */
export const useCreateParallelSavedCart = (
  options?: CreateParallelSavedCartMutationOptions
) => {
  return useCreateParallelSavedCartMutation({
    ...options,
  })
}

/**
 * Update saved cart items
 */
export const useUpdateSavedCartItems = (
  options?: UpdateSavedCartItemsMutationOptions
) => {
  return useUpdateSavedCartItemsMutation({
    ...options,
  })
}

/**
 * Create dealer cart
 */
export const useCreateDealerCart = (
  options?: CreateDealerCartMutationOptions
) => {
  return useCreateDealerCartMutation({
    ...options,
  })
}

/**
 *  Delete parallel cart
 */
export const useDeleteParallelCart = (
  options?: DeleteParallelCartMutationOptions
) => {
  return useDeleteParallelCartMutation({ ...options })
}

/**
 * Fetches All available carts for mini-cart
 */
export const useAllMinicart = (
  options?: QueryHookOptions<AllCartsQuery, AllCartsQueryVariables>
) => {
  return useAllCartsQuery({ ...options })
}
