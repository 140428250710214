// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { QuickCartFragmentDoc } from '../queries/QuickCartQuery.generated';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PatchCartItemMutationVariables = Types.Exact<{
  input: Types.PatchCartItemInput;
  action: Types.CartRequestAction;
}>;


export type PatchCartItemMutation = { __typename?: 'Mutation', patchCartItem: { __typename?: 'Cart', id: string, cartIdAlias?: string | null, count: number, cartType?: string | null, customerServiceInstructions?: string | null, packingSlipNote?: string | null, salesOrderSource?: Types.SalesOrderSource | null, contractNumber?: string | null, selectedPaymentMethod?: string | null, displaySaveCartButton: boolean, isEmproveProductExists: boolean, items: Array<{ __typename?: 'CartItem', id: string, quantity: number, brand?: string | null, quoteItemNumber?: string | null, quoteNumber?: string | null, promoCode?: string | null, productNote?: string | null, yourReference?: string | null, type: Types.CartItemType, displayEditButton: boolean, redirectLinkForEdit?: string | null, configurationId?: string | null, productType?: string | null, isSingleLotSelected?: boolean | null, deviceSerialNumber?: Array<string | null> | null, requestedDeliveryDate?: string | null, material: { __typename?: 'Material', id: string, description?: string | null, displaySellerName?: string | null, erpType?: Array<string | null> | null, productKey?: string | null, product: string, name?: string | null, number: string, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', mediumUrl: string } | null }, image: { __typename?: 'CatalogImage', smallUrl: string, mediumUrl: string, largeUrl: string } }, configuration: Array<{ __typename?: 'ProductConfiguration', name?: string | null, value?: string | null, sapCharc?: string | null }>, emproveDossierInfo?: { __typename?: 'EmproveDossierItem', dossierId?: string | null, dossierSubType?: string | null, dossierSubLength?: number | null, dossierMaterialNumber?: string | null } | null }>, partners: { __typename?: 'CartPartners', SalesOrg?: string | null, billTo?: string | null, contact?: string | null, payer?: string | null, shipTo?: string | null, soldTo?: string | null } } };


export const PatchCartItemDocument = gql`
    mutation PatchCartItem($input: PatchCartItemInput!, $action: CartRequestAction!) {
  patchCartItem(input: $input, action: $action) {
    ...QuickCart
  }
}
    ${QuickCartFragmentDoc}`;
export type PatchCartItemMutationFn = ApolloReactCommon.MutationFunction<PatchCartItemMutation, PatchCartItemMutationVariables>;
export type PatchCartItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PatchCartItemMutation, PatchCartItemMutationVariables>, 'mutation'>;

    export const PatchCartItemComponent = (props: PatchCartItemComponentProps) => (
      <ApolloReactComponents.Mutation<PatchCartItemMutation, PatchCartItemMutationVariables> mutation={PatchCartItemDocument} {...props} />
    );
    

/**
 * __usePatchCartItemMutation__
 *
 * To run a mutation, you first call `usePatchCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchCartItemMutation, { data, loading, error }] = usePatchCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      action: // value for 'action'
 *   },
 * });
 */
export function usePatchCartItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PatchCartItemMutation, PatchCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PatchCartItemMutation, PatchCartItemMutationVariables>(PatchCartItemDocument, options);
      }
export type PatchCartItemMutationHookResult = ReturnType<typeof usePatchCartItemMutation>;
export type PatchCartItemMutationResult = ApolloReactCommon.MutationResult<PatchCartItemMutation>;
export type PatchCartItemMutationOptions = ApolloReactCommon.BaseMutationOptions<PatchCartItemMutation, PatchCartItemMutationVariables>;