import {
  SnackbarMessages,
  useGlobalSnackbar,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { useAddSoftwareItemsToSavedCartMutation } from '@src/mutations/AddSoftwareItemsToSavedCartMutation.generated'
import { useRouter } from '@src/routes'
import { OriginalError, extractData } from '@utils/errorHandler'

export const useNavigateToSavedCart = () => {
  const { setSnackbar } = useGlobalSnackbar()
  const [AddSoftwareItemsToSavedCart] = useAddSoftwareItemsToSavedCartMutation()
  const router = useRouter()

  return async (cartItems, isShoppingCart, setStateMethod) => {
    const cartData = isShoppingCart
      ? cartItems
      : cartItems.map((item) => {
          return {
            materialNumber: item.materialNumber,
            quantity: item.quantity,
          }
        })
    try {
      const { data } = await AddSoftwareItemsToSavedCart({
        variables: {
          items: cartData,
        },
      })
      const cartId = data?.addSoftwareItemsToSavedCart?.savedCart.cartId
      if (cartId) {
        setSnackbar(SnackbarMessages.SaveCartSuccess)
        isShoppingCart && setStateMethod({})
        router.push({ pathname: `/saved-cart/${cartId}` })
      }
    } catch (error) {
      isShoppingCart && setStateMethod({})
      const { errors } = extractData(error as OriginalError)
      const errorCode = errors.length > 0 ? errors[0].code : 'UNKNOWN_ERROR'
      setSnackbar(SnackbarMessages.SaveCartError, errorCode)
    }
  }
}

export const isValidPath = (): boolean => {
  const currentPagePath = window.location.pathname.split('/')[3]
  const paths = ['search', 'product']
  return paths.includes(currentPagePath) ? true : false
}
