// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SaveCartMutationVariables = Types.Exact<{
  input: Types.SaveCartInput;
}>;


export type SaveCartMutation = { __typename?: 'Mutation', saveCart: { __typename?: 'NewSavedCart', savedCart: { __typename?: 'SaveCartId', cartId: string } } };


export const SaveCartDocument = gql`
    mutation SaveCart($input: SaveCartInput!) {
  saveCart(input: $input) {
    savedCart {
      cartId
    }
  }
}
    `;
export type SaveCartMutationFn = ApolloReactCommon.MutationFunction<SaveCartMutation, SaveCartMutationVariables>;
export type SaveCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveCartMutation, SaveCartMutationVariables>, 'mutation'>;

    export const SaveCartComponent = (props: SaveCartComponentProps) => (
      <ApolloReactComponents.Mutation<SaveCartMutation, SaveCartMutationVariables> mutation={SaveCartDocument} {...props} />
    );
    

/**
 * __useSaveCartMutation__
 *
 * To run a mutation, you first call `useSaveCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCartMutation, { data, loading, error }] = useSaveCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveCartMutation, SaveCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SaveCartMutation, SaveCartMutationVariables>(SaveCartDocument, options);
      }
export type SaveCartMutationHookResult = ReturnType<typeof useSaveCartMutation>;
export type SaveCartMutationResult = ApolloReactCommon.MutationResult<SaveCartMutation>;
export type SaveCartMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveCartMutation, SaveCartMutationVariables>;