// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DeleteSavedCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['ID'];
}>;


export type DeleteSavedCartMutation = { __typename?: 'Mutation', deleteSavedCart: boolean };


export const DeleteSavedCartDocument = gql`
    mutation DeleteSavedCart($cartId: ID!) {
  deleteSavedCart(cartId: $cartId)
}
    `;
export type DeleteSavedCartMutationFn = ApolloReactCommon.MutationFunction<DeleteSavedCartMutation, DeleteSavedCartMutationVariables>;
export type DeleteSavedCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSavedCartMutation, DeleteSavedCartMutationVariables>, 'mutation'>;

    export const DeleteSavedCartComponent = (props: DeleteSavedCartComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSavedCartMutation, DeleteSavedCartMutationVariables> mutation={DeleteSavedCartDocument} {...props} />
    );
    

/**
 * __useDeleteSavedCartMutation__
 *
 * To run a mutation, you first call `useDeleteSavedCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedCartMutation, { data, loading, error }] = useDeleteSavedCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useDeleteSavedCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSavedCartMutation, DeleteSavedCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSavedCartMutation, DeleteSavedCartMutationVariables>(DeleteSavedCartDocument, options);
      }
export type DeleteSavedCartMutationHookResult = ReturnType<typeof useDeleteSavedCartMutation>;
export type DeleteSavedCartMutationResult = ApolloReactCommon.MutationResult<DeleteSavedCartMutation>;
export type DeleteSavedCartMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSavedCartMutation, DeleteSavedCartMutationVariables>;