// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateOrdersPendingApprovalMutationVariables = Types.Exact<{
  input: Types.UpdateOrdersPendingApprovalInput;
}>;


export type UpdateOrdersPendingApprovalMutation = { __typename?: 'Mutation', updateOrdersPendingApproval: boolean };


export const UpdateOrdersPendingApprovalDocument = gql`
    mutation UpdateOrdersPendingApproval($input: UpdateOrdersPendingApprovalInput!) {
  updateOrdersPendingApproval(input: $input)
}
    `;
export type UpdateOrdersPendingApprovalMutationFn = ApolloReactCommon.MutationFunction<UpdateOrdersPendingApprovalMutation, UpdateOrdersPendingApprovalMutationVariables>;
export type UpdateOrdersPendingApprovalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrdersPendingApprovalMutation, UpdateOrdersPendingApprovalMutationVariables>, 'mutation'>;

    export const UpdateOrdersPendingApprovalComponent = (props: UpdateOrdersPendingApprovalComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrdersPendingApprovalMutation, UpdateOrdersPendingApprovalMutationVariables> mutation={UpdateOrdersPendingApprovalDocument} {...props} />
    );
    

/**
 * __useUpdateOrdersPendingApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateOrdersPendingApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrdersPendingApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrdersPendingApprovalMutation, { data, loading, error }] = useUpdateOrdersPendingApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrdersPendingApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrdersPendingApprovalMutation, UpdateOrdersPendingApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateOrdersPendingApprovalMutation, UpdateOrdersPendingApprovalMutationVariables>(UpdateOrdersPendingApprovalDocument, options);
      }
export type UpdateOrdersPendingApprovalMutationHookResult = ReturnType<typeof useUpdateOrdersPendingApprovalMutation>;
export type UpdateOrdersPendingApprovalMutationResult = ApolloReactCommon.MutationResult<UpdateOrdersPendingApprovalMutation>;
export type UpdateOrdersPendingApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrdersPendingApprovalMutation, UpdateOrdersPendingApprovalMutationVariables>;