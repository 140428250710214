// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateParallelSavedCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['ID'];
}>;


export type CreateParallelSavedCartMutation = { __typename?: 'Mutation', createParallelSavedCart: boolean };


export const CreateParallelSavedCartDocument = gql`
    mutation CreateParallelSavedCart($cartId: ID!) {
  createParallelSavedCart(cartId: $cartId)
}
    `;
export type CreateParallelSavedCartMutationFn = ApolloReactCommon.MutationFunction<CreateParallelSavedCartMutation, CreateParallelSavedCartMutationVariables>;
export type CreateParallelSavedCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateParallelSavedCartMutation, CreateParallelSavedCartMutationVariables>, 'mutation'>;

    export const CreateParallelSavedCartComponent = (props: CreateParallelSavedCartComponentProps) => (
      <ApolloReactComponents.Mutation<CreateParallelSavedCartMutation, CreateParallelSavedCartMutationVariables> mutation={CreateParallelSavedCartDocument} {...props} />
    );
    

/**
 * __useCreateParallelSavedCartMutation__
 *
 * To run a mutation, you first call `useCreateParallelSavedCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParallelSavedCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParallelSavedCartMutation, { data, loading, error }] = useCreateParallelSavedCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCreateParallelSavedCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateParallelSavedCartMutation, CreateParallelSavedCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateParallelSavedCartMutation, CreateParallelSavedCartMutationVariables>(CreateParallelSavedCartDocument, options);
      }
export type CreateParallelSavedCartMutationHookResult = ReturnType<typeof useCreateParallelSavedCartMutation>;
export type CreateParallelSavedCartMutationResult = ApolloReactCommon.MutationResult<CreateParallelSavedCartMutation>;
export type CreateParallelSavedCartMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateParallelSavedCartMutation, CreateParallelSavedCartMutationVariables>;