// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DeleteCartItemMutationVariables = Types.Exact<{
  cartItemId: Types.Scalars['ID'];
  action: Types.CartRequestAction;
  cartId: Types.Scalars['ID'];
}>;


export type DeleteCartItemMutation = { __typename?: 'Mutation', deleteCartItem: boolean };


export const DeleteCartItemDocument = gql`
    mutation DeleteCartItem($cartItemId: ID!, $action: CartRequestAction!, $cartId: ID!) {
  deleteCartItem(cartItemId: $cartItemId, action: $action, cartId: $cartId)
}
    `;
export type DeleteCartItemMutationFn = ApolloReactCommon.MutationFunction<DeleteCartItemMutation, DeleteCartItemMutationVariables>;
export type DeleteCartItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>, 'mutation'>;

    export const DeleteCartItemComponent = (props: DeleteCartItemComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCartItemMutation, DeleteCartItemMutationVariables> mutation={DeleteCartItemDocument} {...props} />
    );
    

/**
 * __useDeleteCartItemMutation__
 *
 * To run a mutation, you first call `useDeleteCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemMutation, { data, loading, error }] = useDeleteCartItemMutation({
 *   variables: {
 *      cartItemId: // value for 'cartItemId'
 *      action: // value for 'action'
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useDeleteCartItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteCartItemMutation, DeleteCartItemMutationVariables>(DeleteCartItemDocument, options);
      }
export type DeleteCartItemMutationHookResult = ReturnType<typeof useDeleteCartItemMutation>;
export type DeleteCartItemMutationResult = ApolloReactCommon.MutationResult<DeleteCartItemMutation>;
export type DeleteCartItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>;