// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateDealerCartMutationVariables = Types.Exact<{
  orderNumber: Types.Scalars['ID'];
}>;


export type CreateDealerCartMutation = { __typename?: 'Mutation', createDealerCart: boolean };


export const CreateDealerCartDocument = gql`
    mutation CreateDealerCart($orderNumber: ID!) {
  createDealerCart(orderNumber: $orderNumber)
}
    `;
export type CreateDealerCartMutationFn = ApolloReactCommon.MutationFunction<CreateDealerCartMutation, CreateDealerCartMutationVariables>;
export type CreateDealerCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDealerCartMutation, CreateDealerCartMutationVariables>, 'mutation'>;

    export const CreateDealerCartComponent = (props: CreateDealerCartComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDealerCartMutation, CreateDealerCartMutationVariables> mutation={CreateDealerCartDocument} {...props} />
    );
    

/**
 * __useCreateDealerCartMutation__
 *
 * To run a mutation, you first call `useCreateDealerCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealerCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealerCartMutation, { data, loading, error }] = useCreateDealerCartMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useCreateDealerCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDealerCartMutation, CreateDealerCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateDealerCartMutation, CreateDealerCartMutationVariables>(CreateDealerCartDocument, options);
      }
export type CreateDealerCartMutationHookResult = ReturnType<typeof useCreateDealerCartMutation>;
export type CreateDealerCartMutationResult = ApolloReactCommon.MutationResult<CreateDealerCartMutation>;
export type CreateDealerCartMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDealerCartMutation, CreateDealerCartMutationVariables>;