import gql from 'graphql-tag'

export const ORDERS_PENDING_APPROVAL = gql`
  query OrdersPendingApproval {
    ordersPendingApproval {
      count
      ordersForApproval {
        orderId
        orderNumber
        orderTotal
        createdDate
        submittedBy
        poNumber
        approverTypeId
        numOfItems
        approvalAmount
        currency
        inReview
      }
    }
  }
`
