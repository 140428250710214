// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateSavedCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['ID'];
  input: Types.UpdateSavedCartInput;
}>;


export type UpdateSavedCartMutation = { __typename?: 'Mutation', updateSavedCart: boolean };


export const UpdateSavedCartDocument = gql`
    mutation UpdateSavedCart($cartId: ID!, $input: UpdateSavedCartInput!) {
  updateSavedCart(cartId: $cartId, input: $input)
}
    `;
export type UpdateSavedCartMutationFn = ApolloReactCommon.MutationFunction<UpdateSavedCartMutation, UpdateSavedCartMutationVariables>;
export type UpdateSavedCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSavedCartMutation, UpdateSavedCartMutationVariables>, 'mutation'>;

    export const UpdateSavedCartComponent = (props: UpdateSavedCartComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSavedCartMutation, UpdateSavedCartMutationVariables> mutation={UpdateSavedCartDocument} {...props} />
    );
    

/**
 * __useUpdateSavedCartMutation__
 *
 * To run a mutation, you first call `useUpdateSavedCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedCartMutation, { data, loading, error }] = useUpdateSavedCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSavedCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSavedCartMutation, UpdateSavedCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSavedCartMutation, UpdateSavedCartMutationVariables>(UpdateSavedCartDocument, options);
      }
export type UpdateSavedCartMutationHookResult = ReturnType<typeof useUpdateSavedCartMutation>;
export type UpdateSavedCartMutationResult = ApolloReactCommon.MutationResult<UpdateSavedCartMutation>;
export type UpdateSavedCartMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSavedCartMutation, UpdateSavedCartMutationVariables>;