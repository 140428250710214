// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AllCartsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AllCartsQuery = { __typename?: 'Query', getAllCarts: Array<{ __typename?: 'Cart', id: string, cartIdAlias?: string | null, count: number, cartType?: string | null, customerServiceInstructions?: string | null, packingSlipNote?: string | null, salesOrderSource?: Types.SalesOrderSource | null, contractNumber?: string | null, selectedPaymentMethod?: string | null, displaySaveCartButton: boolean, isEmproveProductExists: boolean, items: Array<{ __typename?: 'CartItem', id: string, quantity: number, brand?: string | null, quoteItemNumber?: string | null, quoteNumber?: string | null, promoCode?: string | null, productNote?: string | null, yourReference?: string | null, type: Types.CartItemType, displayEditButton: boolean, redirectLinkForEdit?: string | null, configurationId?: string | null, productType?: string | null, isSingleLotSelected?: boolean | null, deviceSerialNumber?: Array<string | null> | null, requestedDeliveryDate?: string | null, material: { __typename?: 'Material', id: string, description?: string | null, displaySellerName?: string | null, erpType?: Array<string | null> | null, productKey?: string | null, product: string, name?: string | null, number: string, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', mediumUrl: string } | null }, image: { __typename?: 'CatalogImage', smallUrl: string, mediumUrl: string, largeUrl: string } }, configuration: Array<{ __typename?: 'ProductConfiguration', name?: string | null, value?: string | null, sapCharc?: string | null }>, emproveDossierInfo?: { __typename?: 'EmproveDossierItem', dossierId?: string | null, dossierSubType?: string | null, dossierSubLength?: number | null, dossierMaterialNumber?: string | null } | null }>, partners: { __typename?: 'CartPartners', SalesOrg?: string | null, billTo?: string | null, contact?: string | null, payer?: string | null, shipTo?: string | null, soldTo?: string | null } }> };

export type QuickCartFragment = { __typename?: 'Cart', id: string, cartIdAlias?: string | null, count: number, cartType?: string | null, customerServiceInstructions?: string | null, packingSlipNote?: string | null, salesOrderSource?: Types.SalesOrderSource | null, contractNumber?: string | null, selectedPaymentMethod?: string | null, displaySaveCartButton: boolean, isEmproveProductExists: boolean, items: Array<{ __typename?: 'CartItem', id: string, quantity: number, brand?: string | null, quoteItemNumber?: string | null, quoteNumber?: string | null, promoCode?: string | null, productNote?: string | null, yourReference?: string | null, type: Types.CartItemType, displayEditButton: boolean, redirectLinkForEdit?: string | null, configurationId?: string | null, productType?: string | null, isSingleLotSelected?: boolean | null, deviceSerialNumber?: Array<string | null> | null, requestedDeliveryDate?: string | null, material: { __typename?: 'Material', id: string, description?: string | null, displaySellerName?: string | null, erpType?: Array<string | null> | null, productKey?: string | null, product: string, name?: string | null, number: string, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', mediumUrl: string } | null }, image: { __typename?: 'CatalogImage', smallUrl: string, mediumUrl: string, largeUrl: string } }, configuration: Array<{ __typename?: 'ProductConfiguration', name?: string | null, value?: string | null, sapCharc?: string | null }>, emproveDossierInfo?: { __typename?: 'EmproveDossierItem', dossierId?: string | null, dossierSubType?: string | null, dossierSubLength?: number | null, dossierMaterialNumber?: string | null } | null }>, partners: { __typename?: 'CartPartners', SalesOrg?: string | null, billTo?: string | null, contact?: string | null, payer?: string | null, shipTo?: string | null, soldTo?: string | null } };

export type QuickCartPartnersFragment = { __typename?: 'CartPartners', SalesOrg?: string | null, billTo?: string | null, contact?: string | null, payer?: string | null, shipTo?: string | null, soldTo?: string | null };

export type QuickCartItemFragment = { __typename?: 'CartItem', id: string, quantity: number, brand?: string | null, quoteItemNumber?: string | null, quoteNumber?: string | null, promoCode?: string | null, productNote?: string | null, yourReference?: string | null, type: Types.CartItemType, displayEditButton: boolean, redirectLinkForEdit?: string | null, configurationId?: string | null, productType?: string | null, isSingleLotSelected?: boolean | null, deviceSerialNumber?: Array<string | null> | null, requestedDeliveryDate?: string | null, material: { __typename?: 'Material', id: string, description?: string | null, displaySellerName?: string | null, erpType?: Array<string | null> | null, productKey?: string | null, product: string, name?: string | null, number: string, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', mediumUrl: string } | null }, image: { __typename?: 'CatalogImage', smallUrl: string, mediumUrl: string, largeUrl: string } }, configuration: Array<{ __typename?: 'ProductConfiguration', name?: string | null, value?: string | null, sapCharc?: string | null }>, emproveDossierInfo?: { __typename?: 'EmproveDossierItem', dossierId?: string | null, dossierSubType?: string | null, dossierSubLength?: number | null, dossierMaterialNumber?: string | null } | null };

export const QuickCartItemFragmentDoc = gql`
    fragment QuickCartItem on CartItem {
  id
  quantity
  brand
  material {
    id
    description
    displaySellerName
    erpType
    brand {
      key
      name
      color
      logo {
        mediumUrl
      }
    }
    productKey
    product
    image {
      smallUrl
      mediumUrl
      largeUrl
    }
    name
    number
  }
  quoteItemNumber
  quoteNumber
  promoCode
  productNote
  yourReference
  type
  displayEditButton
  redirectLinkForEdit
  configurationId
  configuration {
    name
    value
    sapCharc
  }
  productType
  emproveDossierInfo {
    dossierId
    dossierSubType
    dossierSubLength
    dossierMaterialNumber
  }
  isSingleLotSelected
  deviceSerialNumber
  requestedDeliveryDate
}
    `;
export const QuickCartPartnersFragmentDoc = gql`
    fragment QuickCartPartners on CartPartners {
  SalesOrg
  billTo
  contact
  payer
  shipTo
  soldTo
}
    `;
export const QuickCartFragmentDoc = gql`
    fragment QuickCart on Cart {
  id
  cartIdAlias
  count
  cartType
  items {
    ...QuickCartItem
  }
  customerServiceInstructions
  packingSlipNote
  partners {
    ...QuickCartPartners
  }
  salesOrderSource
  contractNumber
  selectedPaymentMethod
  displaySaveCartButton
  isEmproveProductExists
}
    ${QuickCartItemFragmentDoc}
${QuickCartPartnersFragmentDoc}`;
export const AllCartsDocument = gql`
    query AllCarts {
  getAllCarts {
    ...QuickCart
  }
}
    ${QuickCartFragmentDoc}`;
export type AllCartsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllCartsQuery, AllCartsQueryVariables>, 'query'>;

    export const AllCartsComponent = (props: AllCartsComponentProps) => (
      <ApolloReactComponents.Query<AllCartsQuery, AllCartsQueryVariables> query={AllCartsDocument} {...props} />
    );
    

/**
 * __useAllCartsQuery__
 *
 * To run a query within a React component, call `useAllCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCartsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCartsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllCartsQuery, AllCartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AllCartsQuery, AllCartsQueryVariables>(AllCartsDocument, options);
      }
export function useAllCartsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCartsQuery, AllCartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AllCartsQuery, AllCartsQueryVariables>(AllCartsDocument, options);
        }
export type AllCartsQueryHookResult = ReturnType<typeof useAllCartsQuery>;
export type AllCartsLazyQueryHookResult = ReturnType<typeof useAllCartsLazyQuery>;
export type AllCartsQueryResult = ApolloReactCommon.QueryResult<AllCartsQuery, AllCartsQueryVariables>;