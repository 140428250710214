// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DeleteParallelCartMutationVariables = Types.Exact<{
  action: Types.CartRequestAction;
}>;


export type DeleteParallelCartMutation = { __typename?: 'Mutation', deleteParallelCart: boolean };


export const DeleteParallelCartDocument = gql`
    mutation DeleteParallelCart($action: CartRequestAction!) {
  deleteParallelCart(action: $action)
}
    `;
export type DeleteParallelCartMutationFn = ApolloReactCommon.MutationFunction<DeleteParallelCartMutation, DeleteParallelCartMutationVariables>;
export type DeleteParallelCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteParallelCartMutation, DeleteParallelCartMutationVariables>, 'mutation'>;

    export const DeleteParallelCartComponent = (props: DeleteParallelCartComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteParallelCartMutation, DeleteParallelCartMutationVariables> mutation={DeleteParallelCartDocument} {...props} />
    );
    

/**
 * __useDeleteParallelCartMutation__
 *
 * To run a mutation, you first call `useDeleteParallelCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParallelCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParallelCartMutation, { data, loading, error }] = useDeleteParallelCartMutation({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useDeleteParallelCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteParallelCartMutation, DeleteParallelCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteParallelCartMutation, DeleteParallelCartMutationVariables>(DeleteParallelCartDocument, options);
      }
export type DeleteParallelCartMutationHookResult = ReturnType<typeof useDeleteParallelCartMutation>;
export type DeleteParallelCartMutationResult = ApolloReactCommon.MutationResult<DeleteParallelCartMutation>;
export type DeleteParallelCartMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteParallelCartMutation, DeleteParallelCartMutationVariables>;