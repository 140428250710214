// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateSavedCartItemsMutationVariables = Types.Exact<{
  cartId: Types.Scalars['ID'];
  cartItemsUpdate: Array<Types.CartItemUpdateInput> | Types.CartItemUpdateInput;
}>;


export type UpdateSavedCartItemsMutation = { __typename?: 'Mutation', updateSavedCartItems: boolean };


export const UpdateSavedCartItemsDocument = gql`
    mutation UpdateSavedCartItems($cartId: ID!, $cartItemsUpdate: [CartItemUpdateInput!]!) {
  updateSavedCartItems(cartId: $cartId, cartItemsUpdate: $cartItemsUpdate)
}
    `;
export type UpdateSavedCartItemsMutationFn = ApolloReactCommon.MutationFunction<UpdateSavedCartItemsMutation, UpdateSavedCartItemsMutationVariables>;
export type UpdateSavedCartItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSavedCartItemsMutation, UpdateSavedCartItemsMutationVariables>, 'mutation'>;

    export const UpdateSavedCartItemsComponent = (props: UpdateSavedCartItemsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSavedCartItemsMutation, UpdateSavedCartItemsMutationVariables> mutation={UpdateSavedCartItemsDocument} {...props} />
    );
    

/**
 * __useUpdateSavedCartItemsMutation__
 *
 * To run a mutation, you first call `useUpdateSavedCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedCartItemsMutation, { data, loading, error }] = useUpdateSavedCartItemsMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      cartItemsUpdate: // value for 'cartItemsUpdate'
 *   },
 * });
 */
export function useUpdateSavedCartItemsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSavedCartItemsMutation, UpdateSavedCartItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSavedCartItemsMutation, UpdateSavedCartItemsMutationVariables>(UpdateSavedCartItemsDocument, options);
      }
export type UpdateSavedCartItemsMutationHookResult = ReturnType<typeof useUpdateSavedCartItemsMutation>;
export type UpdateSavedCartItemsMutationResult = ApolloReactCommon.MutationResult<UpdateSavedCartItemsMutation>;
export type UpdateSavedCartItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSavedCartItemsMutation, UpdateSavedCartItemsMutationVariables>;