// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type QuickCartBillingAddressQueryVariables = Types.Exact<{
  id: Types.CartRequestAction;
}>;


export type QuickCartBillingAddressQuery = { __typename?: 'Query', getCart: { __typename?: 'Cart', id: string, billingAddress?: { __typename?: 'CartAddress', attentionTo?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, department?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, buildingRoom?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, organizationName?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street1?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street3?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street4?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street5?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, postOfficeBox?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, city?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, postalCode?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, state?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, country?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null } | null } };

export type CartBillingAddressFragment = { __typename?: 'CartAddress', attentionTo?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, department?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, buildingRoom?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, organizationName?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street1?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street3?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street4?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, street5?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, postOfficeBox?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, city?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, postalCode?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, state?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null, country?: { __typename?: 'LockableValue', value?: string | null, locked?: boolean | null } | null };

export const CartBillingAddressFragmentDoc = gql`
    fragment CartBillingAddress on CartAddress {
  attentionTo {
    value
    locked
  }
  department {
    value
    locked
  }
  buildingRoom {
    value
    locked
  }
  organizationName {
    value
    locked
  }
  street1 {
    value
    locked
  }
  street3 {
    value
    locked
  }
  street4 {
    value
    locked
  }
  street5 {
    value
    locked
  }
  postOfficeBox {
    value
    locked
  }
  city {
    value
    locked
  }
  postalCode {
    value
    locked
  }
  state {
    value
    locked
  }
  country {
    value
    locked
  }
}
    `;
export const QuickCartBillingAddressDocument = gql`
    query QuickCartBillingAddress($id: CartRequestAction!) {
  getCart(id: $id) {
    id
    billingAddress {
      ...CartBillingAddress
    }
  }
}
    ${CartBillingAddressFragmentDoc}`;
export type QuickCartBillingAddressComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuickCartBillingAddressQuery, QuickCartBillingAddressQueryVariables>, 'query'> & ({ variables: QuickCartBillingAddressQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const QuickCartBillingAddressComponent = (props: QuickCartBillingAddressComponentProps) => (
      <ApolloReactComponents.Query<QuickCartBillingAddressQuery, QuickCartBillingAddressQueryVariables> query={QuickCartBillingAddressDocument} {...props} />
    );
    

/**
 * __useQuickCartBillingAddressQuery__
 *
 * To run a query within a React component, call `useQuickCartBillingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickCartBillingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickCartBillingAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuickCartBillingAddressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuickCartBillingAddressQuery, QuickCartBillingAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<QuickCartBillingAddressQuery, QuickCartBillingAddressQueryVariables>(QuickCartBillingAddressDocument, options);
      }
export function useQuickCartBillingAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuickCartBillingAddressQuery, QuickCartBillingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<QuickCartBillingAddressQuery, QuickCartBillingAddressQueryVariables>(QuickCartBillingAddressDocument, options);
        }
export type QuickCartBillingAddressQueryHookResult = ReturnType<typeof useQuickCartBillingAddressQuery>;
export type QuickCartBillingAddressLazyQueryHookResult = ReturnType<typeof useQuickCartBillingAddressLazyQuery>;
export type QuickCartBillingAddressQueryResult = ApolloReactCommon.QueryResult<QuickCartBillingAddressQuery, QuickCartBillingAddressQueryVariables>;